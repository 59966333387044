import { Observer } from 'mobx-react-lite';
import React from 'react';

import PageHeader from '../components/PageHeader/PageHeader';
import PageTemplate from '../components/PageTemplate/PageTemplate';
import APP_CONTENT_CONFIG from '../constants/appContentConfig.constants';
import { PageComponent } from '../types/gatsby.types';
import joinClassNames from '../utils/className.utils';

import './page-contact-page.scss';
import ContactDetails from '../components/ContactDetails/ContactDetails';
import { graphql } from 'gatsby';
import PageSectionPageContent from '../components/PageSectionPageContent/PageSectionPageContent';

export const query = graphql`
  query ($id: String) {
    wpPage(id: { eq: $id }) {
      ...PageWithFeaturedImageFragment
    }
  }
`;

type PageContactProps = {
  data: any,
}

const PageContact: PageComponent<PageContactProps> = props => {
  const {
    data: {
      wpPage: { id, title, content, slug, isFrontPage, featuredImage, seo },
    },
  } = props;

  const { content: contentConfig } = APP_CONTENT_CONFIG.page.contact;
  return <Observer children={() => (
    <PageTemplate
      {...props} // always include
      className={joinClassNames(slug)}
      wpYoastSEO={seo}
      pageSlug={slug}
      pageTemplate='page'
      title={title}
      isFrontPage={isFrontPage}
      featuredMedia={featuredImage?.node}
    >

      <PageHeader className="ContactPageHeader" title={title} hasImage={featuredImage?.node} />

      <PageSectionPageContent content={content} />

      <section className="page-section">
        <div className="container">
          <div className="section-contact__content">
            <h2>{contentConfig.enquiryText}</h2>
            <ContactDetails
              className="contact-page-contact-info-block"
              linkTagClassName="contact-page-contact-method-link"
              contactDetails={contentConfig.contactDetails}
            />
          </div>
        </div>
      </section>

    </PageTemplate>
  )} />
}

export default PageContact;